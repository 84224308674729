<template>
  <div class="login-warp">
    <div class="login-center">
      <div class="login-center-warp container">
        <div class="logo-form-warp">
          <div class="form-warp">
            <div class="logo-loading">
              <div class="logo1"></div>
            </div>
            <form class="logo-form">
              <div class="input-group"></div>
              <div class="input-group">
                <div class="icon"><span class="iconfont icon-mima"></span></div>
                <span class="game-defensil">DEFENSIL GAMING</span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="game-loading-bar">
        <div class="col-lg-8 ml-auto mr-auto mobile-loading">
          <b-progress
            class="loading-progress-bar gold-progress"
            :value="increasing_pct"
          >
          </b-progress>
          <ul class="steps" id="steps" style="margin-top: -31px">
            <li id="step1" :class="step1">
              <span class="step__icon diamond rotate"></span>
            </li>
            <li id="step2" :class="step2">
              <span class="step__icon flower rotateY"></span>
            </li>
            <li id="step3" :class="step3">
              <span class="step__icon heart rotate"></span>
            </li>
            <li id="step4" :class="step4">
              <span class="step__icon spade rotateY"></span>
            </li>
          </ul>
        </div>
        <b-col class="col-lg-2"></b-col>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/loading.scss";

export default {
  name: "LoadingScreenPC",
  data() {
    return {
      animate: true,
      increasing_pct: 0,
      timer: null,
      step1: "step step--incomplete step--active",
      step2: "step step--incomplete step--inactive",
      step3: "step step--incomplete step--inactive",
      step4: "step step--incomplete step--inactive",
    };
  },
  watch: {
    increasing_pct: {
      handler(val) {
        setTimeout(() => {
          this.increasing_pct = Math.min(val + 30, 100);
        }, 1000);

        switch (val) {
          case 12: {
            this.step1 = "step step--complete step--inactive";
            this.step2 = "step step--incomplete step--active";
            break;
          }
          case 38: {
            this.step2 = "step step--complete step--inactive";
            this.step3 = "step step--incomplete step--active";
            break;
          }
          case 61: {
            this.step3 = "step step--complete step--inactive";
            this.step4 = "step step--incomplete step--active";
            break;
          }
          case 89: {
            this.step4 = "step step--complete step--inactive";
            break;
          }
          case 100: {
            localStorage.setItem("isMobile", this.isMobile());
            this.$store.dispatch("gameModule/init", { token: this.$route.query.token, table_id: this.$route.query.table_id});
            break;
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (localStorage.getItem("muteAudio") === null) {
      localStorage.setItem("muteAudio", 0); // Default Audio Playback Setting // 0 play audio // 0 mute
    }
  },
  methods: {
    isMobile() {
      if (
        /Android|android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.login-warp {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: url("/assets/images/loading/loading-background.jpg") no-repeat 50%;
  background-size: cover;
  box-sizing: border-box;
}
.login-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  margin-right: -15px;
  margin-bottom: -15px;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.login-center-warp {
  display: flex;
  min-height: 100%;
  text-align: center !important;
  align-items: center;
  justify-content: center;
}
.logo-form-warp {
  margin-top: -15%;
}
.form-warp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.game-defensil {
  font-size: 2.5rem;
  background: -webkit-linear-gradient(#eee, rgb(194, 153, 99));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.logo-loading {
  width: 300px;
  height: 340px;
  position: relative;
  background: url("/assets/images/defensil-logo-transparent.png") 50% no-repeat;
  background-size: contain;
}
.logo1 {
  width: 300px;
  height: 340px;
  position: absolute;
  top: 0;
  left: 0;
  background: url("/assets/images/defensil-logo-white.png") 50% no-repeat;
  background-size: contain;
  mask: url("/assets/images/defensil-logo-white.png") no-repeat;
  -webkit-mask: url("/assets/images/defensil-logo-white.png") no-repeat;
  -moz-mask: url("/assets/images/defensil-logo-white.png") no-repeat;
  -o-mask: url("/assets/images/defensil-logo-white.png") no-repeat;
  -ms-mask: url("/assets/images/defensil-logo-white.png") no-repeat;
  -webkit-mask-size: auto;
  mask-size: auto;
  -webkit-animation: maskPosition-data-v-06403c1a 2.5s infinite;
  animation: maskPosition-data-v-06403c1a 2.5s infinite;
}

@-webkit-keyframes maskPosition-data-v-06403c1a {
  0% {
    -webkit-mask-position: -5em;
  }
  to {
    -webkit-mask-position: 20em 30em;
  }
}

@keyframes maskPosition-data-v-06403c1a {
  0% {
    -webkit-mask-position: -5em;
  }
  to {
    -webkit-mask-position: 20em 30em;
  }
}

@media screen and (max-width: 375px) and (orientation: portrait) {
  .logo-form-warp {
    margin-top: -90%;
  }

  .logo-loading {
    width: 45vw;
    height: 30vh;
  }
  .logo1 {
    width: 45vw;
    height: 30vh;
  }
  .game-defensil {
    font-size: 5.5vw;
  }
}


@media screen and (max-width: 812px) and (orientation: landscape) {

  .logo-loading {
    width: 45vw;
    height: 30vh;
  }
  .logo1 {
    width: 45vw;
    height: 30vh;
  }
  .game-defensil {
    font-size: 3.5vw;
  }
}
</style>
